






































































































































































































































import { FormFields, FormFieldType, FormFileType, StringValidator } from '@/includes/types/Form.types'
import { InputSetups } from '@/mixins/input-setups'

import {
  Property,
  PropertyFieldType
} from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import getVSelectOptionByValue from 'piramis-js-utils/lib/getVSelectOptionByValue'

import { Component, Emit, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({
  components: {
    Accordion,
    NestedContent,
    ConfigField
  },
  data() {
    return {
      FormFieldType,
    }
  }
})
export default class FormFieldConstructor extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel({ type: Object }) field!: FormFields

  @Prop({ type: Boolean }) disabled!: boolean

  @Prop({ type: Array }) selectedProperties!:Array<number>

  @Prop() fieldModel!:any

  @Emit()
  updateFieldType(type: FormFieldType): FormFieldType {
    return type
  }

  @Watch('field.type')
  onFieldChange(newModel:FormFieldType):void {
    this.updateFieldType(newModel)
    this.validatorExists = false
  }

  validatorExists = false

  get fieldTypeOptions():Array<SelectOption> {
    return [
      { label: this.$t(`form_field_type_${ FormFieldType.String.toLowerCase() }`), value: FormFieldType.String },
      { label: this.$t(`form_field_type_${ FormFieldType.Number.toLowerCase() }`), value: FormFieldType.Number },
      { label: this.$t(`form_field_type_${ FormFieldType.Select.toLowerCase() }`), value: FormFieldType.Select },
      { label: this.$t(`form_field_type_${ FormFieldType.Multiselect.toLowerCase() }`), value: FormFieldType.Multiselect },
      { label: this.$t(`form_field_type_${ FormFieldType.Date.toLowerCase() }`), value: FormFieldType.Date },
      { label: this.$t(`form_field_type_${ FormFieldType.DateTime.toLowerCase() }`), value: FormFieldType.DateTime },
      { label: this.$t(`form_field_type_${ FormFieldType.Double.toLowerCase() }`), value: FormFieldType.Double },
      { label: this.$t(`form_field_type_${ FormFieldType.Files.toLowerCase() }`), value: FormFieldType.Files },
      { label: this.$t(`form_field_type_${ FormFieldType.Flag.toLowerCase() }`), value: FormFieldType.Flag },
    ]
  }

  get selectOption() {
    return getVSelectOptionByValue([ ...this.freeSystemProperties, ...this.freeCustomProperties ], this.field.property_id)
  }

  get freeSystemProperties(): Array<SelectOption> {
    return (this.$store.state.AdminTemplate.runtime_config.system_properties as Array<Property>)
      .filter(this.systemPropertiesFilter)
      .map((prop) => {
        return {
          label: this.$t(`${ snakeCase(prop.name) }_option`).toString(),
          value: prop.id
        }
      })
  }

  get freeCustomProperties(): Array<SelectOption> {
    return this.$store.state.boardsState.activeBoard!.properties
      .filter(this.systemPropertiesFilter)
      .map(({ name, id }) => {
        return {
          label: name,
          value: id,
        }
      })
  }

  get formFileTypeOptions():Array<SelectOption> {
    return [
      { label: this.$t(`form_file_type_${ FormFileType.Media.toLowerCase() }`), value: FormFileType.Media },
      { label: this.$t(`form_file_type_${ FormFileType.Document.toLowerCase() }`), value: FormFileType.Document },
    ]
  }

  get minValue():number {
    if (this.field.type === FormFieldType.Multiselect) {
      if (this.field.max === 0) {
        return this.field.options.length > 0 ? this.field.options.length : 0
      } else {
        return this.field.max!
      }
    }

    if (this.field.type === FormFieldType.String) {
      if (this.field.max === 0) {
        return Number.MAX_SAFE_INTEGER
      } else {
        return this.field.max!
      }
    }

    if (this.field.type === FormFieldType.Files) {
      if (this.field.max === 0) {
        return Number.MAX_SAFE_INTEGER
      } else {
        return this.field.max!
      }
    }

    return Number.MAX_SAFE_INTEGER
  }

  get maxValue(): number {
    if (this.field.type === FormFieldType.Multiselect) {
      return this.field.options.length > 0 ? this.field.options.length : 0
    }

    if (this.field.type === FormFieldType.String) {
      return Number.MAX_SAFE_INTEGER
    }

    if (this.field.type === FormFieldType.Files) {
      return Number.MAX_SAFE_INTEGER
    }

    return Number.MAX_SAFE_INTEGER
  }

  systemPropertiesFilter(prop: Property):boolean {
    if (!this.selectedProperties.includes(prop.id)) {
      if (
        this.field.type === FormFieldType.String
        && (prop.type === PropertyFieldType.Email
          || prop.type === PropertyFieldType.String
          || prop.type === PropertyFieldType.Phone)
      ) {
        return true
      } else if ([ FormFieldType.Multiselect, FormFieldType.Files, FormFieldType.Select ].includes(this.field.type) && prop.type === PropertyFieldType.String) {
        return true
      } else if ([ FormFieldType.Flag ].includes(this.field.type) && prop.type === PropertyFieldType.Flag) {
        return true
      } else {
        return this.field.type.toString() === prop.type.toString()
      }
    }

    return true
  }

  handleChange({ key }:any):void {
    this.field.property_id = key
  }

  onValidatorExistsChange({ checked }:{ checked: boolean, event: Event }):void {
    if (this.field.type === FormFieldType.String) {
      if (!this.field?.validator) {
        this.$set(this.field, 'validator', {})
      }

      if (!checked) {
        this.field.validator = null
      } else {
        this.field.validator = {} as StringValidator
        this.$set(this.field.validator, 'pattern', '')
        this.$set(this.field.validator, 'negate', false)
        this.$set(this.field.validator, 'message', '')
      }
    }
  }

  onPatternChange(value:Event):void {
    if (this.field.type === FormFieldType.String && this.field.validator) {
      this.field.validator.pattern = (value.target as HTMLInputElement).value.trim()
    }
  }

  checkMinMaxFields() {
    if (this.field.type === FormFieldType.Multiselect || this.field.type === FormFieldType.Files || this.field.type === FormFieldType.String) {
      if (!this.field?.min) {
        this.$set(this.field, 'min', 0)
      }

      if (!this.field?.max) {
        if (this.field?.limit) {
          this.$set(this.field, 'max', this.field?.limit)
        } else {
          this.$set(this.field, 'max', 0)
        }
      }
    }
  }

  mounted():void {
    this.checkMinMaxFields()

    this.validatorExists = this.field.type === FormFieldType.String && !!this.field?.validator
  }
}
