var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-item"},[_c('page-title',{attrs:{"has-anchor-icon":false}},[_c('div',{staticClass:"flex sm:flex-row flex-col gap-2",attrs:{"slot":"custom-button"},slot:"custom-button"},[(!_vm.disabledFields)?_c('a-button',{staticClass:"btn-success",attrs:{"icon":"save"},on:{"click":_vm.handleSaveClick}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),(!_vm.$screen.xl)?_c('a-button',{staticClass:"select-none",attrs:{"type":"primary","icon":"eye"},on:{"click":function($event){_vm.isActive = true}}},[_vm._v(" "+_vm._s(_vm.$t('form_preview'))+" ")]):_vm._e(),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1)]),_c('a-drawer',{staticClass:"form-preview",attrs:{"placement":"right","width":_vm.drawerWidth,"visible":_vm.isActive,"title":_vm.$t('form_preview')},on:{"close":function($event){_vm.isActive = false}}},[(_vm.formModel !== null && !_vm.$screen.xl)?_c('form-preview',{attrs:{"form":_vm.formModel}}):_vm._e()],1),_c('a-modal',{attrs:{"footer":null,"force-render":""},model:{value:(_vm.successMessageSettingModal),callback:function ($$v) {_vm.successMessageSettingModal=$$v},expression:"successMessageSettingModal"}},[(_vm.formModel)?_c('form-success-message-setting',{model:{value:(_vm.formModel.config),callback:function ($$v) {_vm.$set(_vm.formModel, "config", $$v)},expression:"formModel.config"}}):_vm._e()],1),(_vm.formModel !== null)?_c('div',{staticClass:"flex flex-col gap-5"},[_c('a-card',{staticClass:"w-full self-start",attrs:{"body-style":_vm.cardStyles}},[_c('div',{staticClass:"flex lg:flex-row flex-col gap-5"},[_c('div',{staticClass:"lg:w-1/2 w-full"},[_c('text-input',{attrs:{"id":"form-name","setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.formModel,
                'key': 'name',
                'validation': 'required',
                'disabled': _vm.disabledFields
              }
            }}}),_c('switch-input',{attrs:{"setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.formModel,
                'key': 'disposable',
                'disabled': _vm.disabledFields
              }
            }}}),_c('editor-input',{attrs:{"setup":{
              'func': _vm.modelSetter,
              'args': {
                'model': _vm.formModel,
                'key': 'description',
                'disabled': _vm.disabledFields,
                'disabledPanelElements': ['tgspoiler', 'strike'],
              }
            }}})],1),_c('div',{staticClass:"lg:w-1/2 text-center"},[_c('a-button',{staticClass:"mt-5 btn-wrap",attrs:{"type":"primary","icon":"setting"},on:{"click":function($event){_vm.successMessageSettingModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('form_success_message_button_title'))+" ")]),(_vm.formModel.config && _vm.formModel.config.success_message)?[(_vm.$screen.lg)?_c('div',[_c('success-message-preview',{staticClass:"mt-5",model:{value:(_vm.formModel.config),callback:function ($$v) {_vm.$set(_vm.formModel, "config", $$v)},expression:"formModel.config"}})],1):_c('accordion',{staticClass:"mx-2 mt-2",attrs:{"open-title":_vm.$t('success_message_preview_accordion_title'),"close-title":_vm.$t('success_message_preview_accordion_title')}},[_c('success-message-preview',{staticClass:"mt-5",model:{value:(_vm.formModel.config),callback:function ($$v) {_vm.$set(_vm.formModel, "config", $$v)},expression:"formModel.config"}})],1)]:_vm._e()],2)])]),_c('div',{staticClass:"flex gap-2",style:(_vm.$screen.xl ? { maxHeight: '90vh' } : {})},[_c('div',{staticClass:"max-h-full xl:w-1/2 w-full"},[_c('p',{staticClass:"mb-2 text-2xl font-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('form_editor'))+" ")]),(_vm.formModel.config.fields !== null)?_c('fields-list',{staticClass:"w-full max-h-full overflow-y-scroll",attrs:{"fields":_vm.formModel.config.fields,"disabled":_vm.disabledFields},on:{"updateFields":function($event){_vm.formModel.config.fields = $event}}}):_vm._e()],1),(_vm.$screen.xl)?_c('div',{staticClass:"w-1/2 max-h-full overflow-y-scroll"},[_c('p',{staticClass:"mb-2 text-2xl font-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t('form_preview'))+" ")]),_c('a-card',{staticClass:"border-none",attrs:{"body-style":{ padding: 0 }}},[(_vm.formModel !== null && _vm.$screen.xl)?_c('form-preview',{attrs:{"form":_vm.formModel}}):_vm._e()],1)],1):_vm._e()])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }