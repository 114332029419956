

























import { FormConfig } from '../../../includes/types/Form.types'
import FormSuccessMessageImageList from '@/components/Forms/components/FormSuccessMessageImageList.vue'

import { Component, VModel } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    FormSuccessMessageImageList
  }
})
export default class SuccessMessagePreview extends Vue {
  @VModel() formConfig!:FormConfig
}
