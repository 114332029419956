




















































































































































































import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { InputSetups } from '@/mixins/input-setups'
import FieldsList from '@/components/Forms/FieldsList.vue'
import { Form, FormFieldType } from '@/includes/types/Form.types'
import { FormService } from '@/includes/services/FormService'
import { errorNotification } from '@/includes/services/NotificationService'
import FormPreview from '@/components/Forms/components/FormPreview.vue'
import FullForm from '@/components/Forms/FullForm.vue'
import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import FormSuccessMessageSetting from '@/components/Forms/components/FormSuccessMessageSetting.vue'
import { parseSuccessMessage } from '@/components/Forms/logic/FormsHelper'
import SuccessMessagePreview from '@/components/Forms/components/SuccessMessagePreview.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    SuccessMessagePreview,
    FormSuccessMessageSetting,
    FormPreview,
    PageTitle,
    FieldsList,
    FullForm,
    Accordion
  }
})
export default class FormItem extends Mixins<UseFields, InputSetups, DrawerWidthMixin>(UseFields, InputSetups, DrawerWidthMixin) {
  formModel: Omit<Form, 'created'|'updated'> | null = null

  isActive = false

  disabledFields = false

  successMessageSettingModal = false

  get action(): BaseItemViewAction {
    return this.$route.params.actionType.toString() as BaseItemViewAction
  }

  get formKey(): string {
    return this.$route.params.formKey.toString()
  }

  get cardStyles(): Partial<CSSStyleDeclaration> {
    if (this.windowWidth < 1280) {
      return {
        padding: '5px'
      }
    }

    return {}
  }

  validateFormFields(formModel: Omit<Form, 'created'|'updated'>): Promise<Omit<Form, 'created'|'updated'>> {
    return new Promise((resolve, reject) => {
      if (formModel.name === '') {
        reject(this.$t('create_form_form_title_empty_error'))
      }

      if (!formModel.config.fields.length) {
        reject(this.$t('create_form_fields_empty_error'))
      }

      // TODO
      const successMessage = formModel.config.success_message

      if (successMessage?.attachments?.length && (!successMessage?.text?.length || (successMessage?.text as string) === '\n')) {
        reject(this.$t('fill text in success message'))
      }

      formModel.config.fields.forEach(f => {
        if (f.name === '') {
          reject(this.$t('create_form_field_name_empty_error', [ f.name ]))
        } else if (f.type === FormFieldType.Multiselect && f.options.length < 1) {
          reject(this.$t('create_form_field_option_empty_error', [ f.name ]))
        }
      })

      resolve(formModel)
    })
  }

  handleSaveClick(): void {
    if (this.formModel !== null) {
      if (this.action === BaseItemViewAction.New || this.action === BaseItemViewAction.Copy) {
        this.createForm(this.formModel)
      }

      if (this.action === BaseItemViewAction.Edit) {
        this.saveForm(this.formModel)
      }
    }
  }

  saveForm(formModel: Omit<Form, 'created'|'updated'>): void {
    this.validateFormFields(formModel)
      .then(form => {
        form.config = parseSuccessMessage(form.config)

        this.$store.dispatch('updateForm', form)
          .then(() => this.$router.push({ name: "Forms" }))
      })
      .catch(errorNotification)
  }

  createForm(formModel: Omit<Form, 'created'|'updated'>): void {
    this.validateFormFields(formModel)
      .then(form => {
        form.config = parseSuccessMessage(form.config)

        this.$store.dispatch('createForm', form)
          .then(() => this.$router.push({ name: 'Forms' }))
      })
      .catch(errorNotification)
  }

  getForm(): Promise<void> {
    return new Promise(resolve => {
      this.$baseTemplate.loader.open()

      FormService.getForm('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        key: this.formKey
      })
        .then(({ form }) => {
          const { answers, ...formModel } = form

          if (this.action === BaseItemViewAction.Copy) {
            const { key, ...otherFields } = formModel

            this.formModel = otherFields
          } else {
            this.formModel = formModel
          }
        })
        .catch(errorNotification)
        .finally(() => {
          this.$baseTemplate.loader.close()
          resolve()
        })
    })
  }

  mounted(): void {
    if (this.action === BaseItemViewAction.Edit) {
      this.getForm()
    } else if (this.action === BaseItemViewAction.Watch) {
      this.getForm()
      this.disabledFields = true
    } else if (this.action === BaseItemViewAction.Copy) {
      this.getForm()
        .then(() => this.formModel!.name = this.$t('copy', { copy: this.formModel!.name }).toString())
    } else {
      this.formModel = {
        name: '',
        description: '',
        disposable: false,
        config: {
          fields: [],
          success_message: {
            text: '',
            attachments: []
          }
        }
      }
    }
  }
}
